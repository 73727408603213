<template>
	<transition name="fade-in-up">
		<router-view></router-view>
	</transition>
</template>

<script>
import { isAuthenticated } from "@/core/services/jwt.service";
export default {
	beforeCreate() {
		if (isAuthenticated()) {
			this.$router.push({ name: "dashboard" });
		} else {
			let route = { ...this.$route };
			if (
				route.name == "login-sales" ||
				route.name == "login-accounts" ||
				route.name == "login-customers" ||
				route.name == "login-seo" ||
				route.name == "login"
			) {
				this.$router.push({ name: route.name }).catch(() => {});
			} else {
				this.$router.push({ name: "login-sales" }).catch(() => {});
			}
		}
	},
};
</script>
